import { useTranslation } from 'react-i18next';
import { SideContainer } from '../components/SideContainer';
import { Title } from '../components/Title';

export const End = () => {
	const {t} = useTranslation();
	return (
		<SideContainer id={'pc-end'} style={{display: 'none'}}>
			<Title>{t('pages.playing.thanks')}</Title>
		</SideContainer>
	);
};

