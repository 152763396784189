import * as HC from './HostController.js';

export function Initialize(){
	window.addEventListener('keydown', onDocumentKeyDown, false);
	document.addEventListener('video_playing', function(){ ChangeState('togglePause');});
	document.addEventListener('video_paused', function(){ ChangeState('empty');});
	document.addEventListener('video_ended', function(){ ChangeState('empty');});
}

function onDocumentKeyDown(event){
	switch(event.which){
	case 32:
		Space();
		break;

	case 49:
		ForceOption(1);
		break;

	case 50:
		ForceOption(2);
		break;

	case 51:
		ForceOption(3);
		break;

	case 52:
		ForceOption(4);
		break;
	}
}

const stateContinue = new Event('state_continue');
const stateTogglePause = new Event('state_togglePause');


function Space(){
	switch(state){
	case 'togglePause':
		document.dispatchEvent(stateTogglePause);
		break;

	case 'continue':
		document.dispatchEvent(stateContinue);
		break;
	}
}

function ForceOption(i){
	if(state == 'continue'){
		HC.OverwriteResults(i);
	}
}

let state = 'empty';

export function ChangeState(_state){
	state =_state; 
}   

Initialize();