import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Connecting = () =>{
	const {t} = useTranslation();
	return (
		<Container id={'pc-connecting'} className='d-none'>
			<h2 className="text-center">{t('pages.connecting.message')}</h2>
		</Container>
	);
};

// styled components

const Container = styled.div`
	flex-grow:1;
	display: flex;
	align-items: center;
	justify-content: center;
`;