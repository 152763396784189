import i18next from 'i18next';
import * as Websockets from './websockets.js';

let joinForm;
let joinField;

let initialized = false;


export function Initialize(){

	// prevent initialization to happen multiple times
	if(initialized) return;
	initialized = true;

	joinForm =  document.getElementById('form-join');
	joinField = document.getElementById('form-control');
	document.getElementById('form-join-submit').addEventListener('click', function(){validateCode();});

	// Decisions
	setTimeout(() => {
		for(let i = 1; i < 5; i++){
			document.getElementById('player-decision-' + i + '-btn').addEventListener('click', function(){MakeDecision(i);});
		}
	}, 1000);

}

// Websockets

let ws;
let roomcode;
let status = 'empty';

function Connect(code){
	status = 'connecting';
	Websockets.Connect(ConnectCallback, code);
	OpenPC('pc-connecting');
	setTimeout(function(){ConnectingTimeout();}, 4000);
}

function ConnectingTimeout(){
	if(status != 'connected'){
		OpenPC('pc-join', document.getElementById('connection-notification').innerHTML = i18next.t('pages.playing.failed'));
		ws.close();
		status = 'empty';
	}
}

function ConnectCallback(data){
	roomcode = data.roomcode;
	ws = data.ws;
	ws.onmessage = ReceiveMessage;
	let message = {
		'type' : 'ping',
	};
	ws.send(JSON.stringify(message));
}

function ReceiveMessage(message){
	let data = JSON.parse(message.data);

	let action = data.type;

	if(data.type == 'pong'){
		document.getElementById('player-roomcode').innerHTML = roomcode;
		status = 'connected';
		action = data.state;
	}

	switch(action){
	case 'pong':
		OpenPC('pc-connected');
		break;
	case 'scenario-start':
		OpenPC('pc-playing');
		document.getElementById('join-status').innerHTML = i18next.t('pages.playing.watch');
		document.getElementById('join-status').classList.remove('d-none');

		break;
	case 'scenario-end':
		OpenPC('pc-playing');
		document.getElementById('join-status').innerHTML = i18next.t('pages.playing.wait');
		document.getElementById('join-status').classList.remove('d-none');

		break;
	case 'decisions-start':
		OpenPC('pc-playing');
		document.getElementById('player-decisions-title').innerHTML = i18next.t('pages.playing.choose');

		// document.getElementById('join-status').innerHTML = 'Maak een keuze!';
		document.getElementById('join-status').classList.add('d-none');
		ShowDecisions(data.data);
		break;
	case 'decisions-end':
		OpenPC('pc-playing');
		document.getElementById('join-status').innerHTML = i18next.t('pages.playing.watch_screen');
		document.getElementById('join-status').classList.remove('d-none');

		HideDecisions();
		break;
	case 'conn-ping':
		// eslint-disable-next-line no-case-declarations
		let response = {type : 'conn-pong'};
		ws.send(JSON.stringify(response));
		break;
	case 'end':
		OpenPC('pc-playing');

		document.getElementById('join-status').innerHTML = i18next.t('pages.playing.thanks');
		document.getElementById('join-status').classList.remove('d-none');
	}

	//End session when no messages have been received for 5 seconds.
	clearTimeout(disconnectTimer);
	disconnectTimer =  setTimeout(HostDisconnect, 5000);
}

let disconnectTimer;

function HostDisconnect(){
	OpenPC('pc-playing');
	document.getElementById('join-status').innerHTML = i18next.t('pages.playing.connection');
	HideDecisions();
}

// page content

let activePCTag = 'pc-join';

function OpenPC(tag){
	document.getElementById(activePCTag).classList.add('d-none');
	document.getElementById(tag).classList.remove('d-none');
	activePCTag = tag;
}

// Form

function validateCode() {
	if(status != 'empty'){
		return;
	}
	var x = joinForm.elements[0].value;
	if (x == '') {
		alert('Voer een code in');
		return false;
	}
	else{
		Connect(x);
	}
}

// Decisions

function MakeDecision(id){
	window.blur();
	document.getElementById('player-decisions').style.pointerEvents = 'none';
	for(let i = 1; i < 5; i++){
		if(i != id){
			document.getElementById('player-decision-' + i + '-btn').disabled = true;
		}
		else{
			//
		}
	}
	//document.getElementById('player-decisions').classList.add('pointer-events-none', 'd-none');
	document.getElementById('player-decisions-title').innerHTML = i18next.t('pages.playing.choosed');

	ws.send(JSON.stringify({'type': 'answer', 'answer': id}));
}

function ShowDecisions(data){
	document.getElementById('player-decisions').style.pointerEvents = 'all';
	document.getElementById('player-decisions').classList.remove('d-none');
	document.getElementById('player-decisions').classList.add('d-flex');

	for(let i = 1; i < 5; i++){
		document.getElementById('player-decision-' + i + '-btn').disabled = false;
		document.getElementById('player-decision-' + i + '-text').innerHTML = data[i - 1].text;
		document.getElementById('player-decision-' + i + '-btn').blur();
	}
}

function HideDecisions(){
	document.getElementById('player-decisions').classList.add('d-none');
	document.getElementById('player-decisions').classList.remove('d-flex');
}
