import i18next, { t } from 'i18next';
import { Button } from './Button';
import React from 'react';
import styled from 'styled-components';
import nlFlag from '../../assets/images/nl.png';
import enFlag from '../../assets/images/en.png';

const SwapLanguageButton = () => {

	const SwapLanguage = () => {
		localStorage.setItem('i18nextLng', i18next.language === 'nl' ? 'en' : 'nl');
		location.reload();
	};

	return (
		<StyledButton onClick={SwapLanguage}>
			<Flag language={i18next.language}/>{t('pages.join.switchlanguage')}
		</StyledButton>
	);
};

// sc

const StyledButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Flag = styled.div`
	height: 1em;
	background-image: url(${p => p.language === 'nl' ? enFlag : nlFlag});
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 50px;
	margin-right: 15px;
`;

export default SwapLanguageButton;