
export default {

	'general': {
		'start' : 'start',
		'close' : 'afsluiten'
	},
	'pages': {
		'landing':{
			'title': ' aanmelden en uitleg ',
			'call_to_action1' :'Ga naar ',
			'call_to_action2' :'en vul deze code in:',
			'note': 'Als iedereen is aangemeld kan de game beginnen.',
			'description' : 'Je gaat straks verschillende scenario’s zien op het grote scherm. Op een gegeven moment krijg jij de keuze hoe de hoofdpersoon moet reageren. De keuze maak je op je telefoon of tablet. Je hebt hier kort de tijd voor.',
			'question' : 'Wat zou jij doen in elke situatie?'
		},
		'select':{
			'title': 'Kies een scenario',
			'online_title': 'Online',
			'aanmeldformulier_title': 'Aanmeldformulier',
			'achterindebus_title': 'Achter in de bus',
			'dugout_title': 'Dugout',
			'decornervlag_title': 'De cornervlag',
			'penalty_title': 'Penalty',
			'kleedkamer_title': 'Kleedkamer',
			'tafelvoetbal_title': 'Tafelvoetbal',
			'deparkeerplaats_title': 'De parkeerplaats',
		},
		'video': {
			'cornervlag': {
				'text1': 'Doorspelen',
				'text2': 'Stoppen met spelen',
				'text3': 'Scheids roepen',
				'text4': 'Niets doen',
				'id1': '493730727',
				'id2': '493730856',
				'id3': '493730946',
				'id4': '493731062'
			},
			'id': '488979533'
		},
		'videoend': {
			'question' : 'andere keuzemogelijkheid bekijken?',
			'back' : 'terug naar hoofdmenu'
		},
		'connected':{
			'title':'Uitleg',
			'connected_to':'Verbonden met sessie:',
			'description':'Je gaat straks verschillende scenario’s zien op het grote scherm. Op een gegeven moment krijg jij de keuze hoe de hoofdpersoon moet reageren. Je hebt hier kort de tijd voor. Wat zou jij doen in elke situatie?'
		},
		'connecting':{
			'message': 'Verbinden...'
		},
		'join':{
			'title':'WELKOM',
			'description': 'Vul de code in en doe mee met de game! De code is te vinden op het grote scherm.',
			'enter': 'ENTER',
			'list': 'VRAGENLIJST',
			'switchlanguage': 'switch to english'

		},
		'playing':{
			'choose': 'maak een keuze!',
			'watch': 'Kijk het scenario!',
			'failed':'Verbinding mislukt, check de code en probeer het nogmaals.',
			'wait':'Wacht tot er een scenario wordt afgespeeld!',
			'watch_screen':'Kijk op het scherm voor het resultaat!',
			'thanks':'Bedankt voor het spelen!',
			'connection':'Verbinding met sessie verbroken!',
			'alert': 'Voer een code in',
			'choosed': 'Je hebt een keuze gemaakt',
			'placeholder_code_field': 'vul hier de code in ...'
		},
		'footer':{
			'connected':'verbonden spelers:'
		}
	},

};