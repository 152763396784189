import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './GlobalStyle';
import Theme from './theme';

const CustomThemeProvider = ({children}) => {
	return(
		<ThemeProvider theme={Theme}>
			<GlobalStyle/>
			{children}
		</ThemeProvider>
	);
};

export default CustomThemeProvider;