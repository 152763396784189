import { ReactNode } from 'react';

import { GlobalContextProvider } from './global/GlobalContext';

const ContextProvider = (props) => {
	return (			
		<GlobalContextProvider>		
			{props.children}
		</GlobalContextProvider>
	);
};

export default ContextProvider;