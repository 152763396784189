import styled from 'styled-components';
import logo from '../../assets/images/aftraplogo.png';
import Theme from '../../style/theme';

export const Logo = () => {
	return (
		<Container>
			<Image/>
		</Container>
	);
};

// styled components

const Container = styled.div`
    position: relative;
    height: 300px;
    width: 2220px;
    background-color: rgb(0,0,0,0.4);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0% 100%);

	// Extra small screens
	@media (max-width: ${Theme.responsive.media.sm}){
		height: 250px;
		width: 1900px;
	}

`;

const Image = styled.div`
    position: absolute;

    aspect-ratio: 1.875/1;

    background-image: url(${logo}); 
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    z-index: 2;

	// Extra small screens
	@media (max-width: ${Theme.responsive.media.sm}){
		top:${Theme.responsive.whitespace.xs}px;
		margin-inline: ${Theme.responsive.whitespace.xs}px;
		width: calc(100vw - ${Theme.responsive.whitespace.xs * 2}px)
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
        width: 350px;
		left:${Theme.responsive.whitespace.sm}px;top:${Theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
        width: 350px;
		left:${Theme.responsive.whitespace.lg}px;top:${Theme.responsive.whitespace.lg}px;

	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){   
        width: 350px;
		left:${Theme.responsive.whitespace.xl / 2}px;top:${Theme.responsive.whitespace.xl / 2}px;
	}

`;