import { useContext } from 'react';
import { createContext, useContex } from 'react';

const GlobalContext = createContext({});

const GlobalContextProvider = (props) => {

	// general

	const passedFunctions = {

	};

	const passedValues = {

	};		

	//#region render

	return (
		<GlobalContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</GlobalContext.Provider>
	);

	//#endregion
};

// exports

const useGlobalContext = () => useContext(GlobalContext);

export {GlobalContextProvider, GlobalContext, useGlobalContext};
