import styled from 'styled-components';
import Theme from '../../style/theme';

export const SideContainer = styled.div`

	position: relative;
	z-index: 3;
	
	margin-top: -100px;

	// Extra small screens
	@media (min-width: ${Theme.responsive.media.xs}){
		margin-left: calc(${Theme.responsive.whitespace.xs * 2}px + 350px);
		margin-right: ${Theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		margin-left: calc(${Theme.responsive.whitespace.sm * 2}px +  350px);
		margin-right: ${Theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		margin-left: calc(${Theme.responsive.whitespace.lg * 2}px +  350px);
		margin-right: ${Theme.responsive.whitespace.lg}px;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		margin-left: calc(${(Theme.responsive.whitespace.xl / 2) * 2}px + 350px);
		margin-right: ${Theme.responsive.whitespace.xl /2}px;
	}

`;