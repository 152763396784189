import { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as JoinController from '../../js/JoinController.js';
import Theme from '../../style/theme.js';
import { Logo } from '../components/Logo.jsx';
import { Title } from '../components/Title.jsx';
import { Connected } from './Connected.jsx';
import { Connecting } from './Connecting.jsx';
import { Join } from './Join.jsx';
import { Playing } from './Playing.jsx';


export const Player = () => {


	const [initalized, setInitalized] = useState(false);
	useEffect(() => {
		if(initalized) return;
		JoinController.Initialize();
		setInitalized(true);
	},[]);

	return (
		<Container>
			{/* <div id="aftrap-logo" className="mt-md-5 mt-3"></div> */}
			<Logo/>
			<SubContainer>
				<Join/>
				<Connecting/>
				<Connected/>
				<Playing/>
			</SubContainer>
		</Container>
	);
}; 

// styled components

const Container = styled.div`
	min-height: 100%;
	display: flex;
	flex-direction: column;
`;

const SubContainer = styled.div`
	position: relative;
	margin-top: -20px;
	flex-grow: 1;
	display: flex;

	// Extra small screens
	@media (max-width: ${Theme.responsive.media.sm}){
		margin-inline: ${Theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		margin-inline: ${Theme.responsive.whitespace.sm}px;

	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		margin-inline: ${Theme.responsive.whitespace.lg}px;

	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){   
		margin-inline: ${Theme.responsive.whitespace.xl / 2}px;
	}

	z-index: 2;
`;
    