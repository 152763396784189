
export default {

	'general': {
		'start' : 'start',
		'close' : 'exit'
	},
	'pages': {
		'landing':{
			'title': ' sign on and explanation ',
			'call_to_action1' :'Go to ',
			'call_to_action2' :'and fill in this code:',
			'note': 'The game can begin as soon as everyone has joined.',
			'description' : 'You are soon going to see different scenarios on the main screen. At a certain point, you get to choose how the main character reacts. You make the choice on your phone or tablet. You have a little time for this.',
			'question' : 'What would you do in each situation?'
		},
		'select':{
			'title': 'Choose a scenario',
			'online_title': 'Online',
			'aanmeldformulier_title': 'Aanmeldformulier',
			'achterindebus_title': 'In the back of the bus',
			'dugout_title': 'Dugout',
			'decornervlag_title': 'The corner flag',
			'penalty_title': 'Penalty',
			'kleedkamer_title': 'The locker room',
			'tafelvoetbal_title': 'Table football',
			'deparkeerplaats_title': 'The parking lot',
		},
		'video': {
			'cornervlag': {
				'text1': 'Continue playing',
				'text2': 'Stop playing',
				'text3': 'Call for the referee',
				'text4': 'Do nothing',
				'id1': '919204912',
				'id2': '919204856',
				'id3': '919204883',
				'id4': '919204810'
			},
			'id': '919200294'
		},
		'videoend': {
			'question' : 'see other choices?',
			'back' : 'back to main menu'
		},
		'connected':{
			'title':'EXPLANATION',
			'connected_to':'Connected to session:',
			'description':'You are soon going to see different scenarios on the main screen. At a certain point, you get to choose how the main character reacts. You make the choice on your phone or tablet. You have a little time for this.'
		},
		'connecting':{
			'message': 'Connecting...'
		},
		'join':{
			'title':'WELCOME',
			'description': 'Fill in the code and join the game! You can find the code on the main screen.',
			'enter': 'ENTER',
			'list': 'QUESTIONAIRE',
			'switchlanguage': 'Verander naar nederlands'
		},
		'playing':{
			'choose': 'make a choice!',
			'watch': 'Watch the scenario!',
			'failed':'Connection failed, check the code and try again.',
			'wait':'Wait till the host starts a scenario!',
			'watch_screen':'Look at the screen for the results!',
			'thanks':'Thanks for playing!',
			'connection':'Connection lost!',
			'alert': 'Fill in the code',
			'choosed': 'You have submitted your choice',
			'placeholder_code_field': 'fill in the code...'
		},
		'footer':{
			'connected':'connected:'
		}
	},

};