import styled from 'styled-components';
import Theme from '../../style/theme';
import { Button } from '../components/Button';
import { SideContainer } from '../components/SideContainer';
import { Title } from '../components/Title';
import { useTranslation } from 'react-i18next';


export const VideoEnd = () => {
	const {t} = useTranslation();
	
	return(
		<StyledSideContainer id={'pc-videoend'} style={{display: 'none'}}>
			<Title style={{width: '100%', textAlign: 'center'}}>{t('pages.videoend.question')}</Title>
			{ [...Array(4)].map((value, index) => 
				<Button style={{padding: '5px'}}
					key={`ves-option-${index + 1}`}
				><Indexing>{index+1}. </Indexing> <span  style={{color: 'white', textTransform: 'none'}} id={`ves-option-${index + 1}`}>
				ves option </span></Button>
			)}
			<div style={{height: '50px'}}></div>
			<Button id={'videoend-return-btn'} style={{marginTop: 'auto', marginBottom: '50px'}}>{t('pages.videoend.back')}</Button>
		</StyledSideContainer>
	);
};

const StyledSideContainer =  styled(SideContainer)`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	& > button {
		margin-bottom: 10px;
	}
`;

const Indexing = styled.span`
	color: ${Theme.colors.primary}
`;