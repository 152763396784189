// Imports 

import Player from '@vimeo/player';
import * as State from './State.js';

// Variables

let embed;
let player;

let initialized = false;

export function Initialize(){

	// prevent initialization to happen multiple times
	if(initialized) return;
	initialized = true;

	embed = document.getElementById('embed');
	player = new Player(embed);

	// Listeners
	document.addEventListener('pause', () => {Pause();});
	document.addEventListener('resume', Play);

	player.on('ended', OnEnded);
	player.on('cuepoint', OnCuePoint);
	player.on('loaded', (e) => OnLoaded(e));
	player.on('play', OnPlaying);

	window.addEventListener('keydown', onDocumentKeyDown, false);
}

function onDocumentKeyDown(event){
	switch(event.which){
	case 39:
		player.getDuration().then(function(duration) {
			player.setCurrentTime(duration - 10);
		});
		break;

	case 40:
		player.getDuration().then(function(duration) {
			player.setCurrentTime(duration - 1);
		});
		break;

	}
}

// Events


const endedEvent = new Event('video_ended');
//const cuepointEvent = new CustomEvent('video_cuepoint');
const loadedEvent = new Event('video_loaded');
const playingEvent = new Event('video_playing');
const pausedEvent = new Event('video_paused');


function OnEnded(){
	document.dispatchEvent(endedEvent);
}

function OnCuePoint(event){
	player.removeCuePoint(event.id);
	document.dispatchEvent(new CustomEvent('video_cuepoint', {detail : event}));
}

function OnLoaded(e){
	document.dispatchEvent(loadedEvent);
}

function OnPlaying(){
	document.dispatchEvent(playingEvent);
}



// Public Functions

// let bool = false;
// let count = 1;

export function Load(id, cuePointTimestamp){
	player.loadVideo(id).then(function(id) {
		if(cuePointTimestamp != null){
			player.addCuePoint(cuePointTimestamp, {'event':'decisions'});
		}
		Play();
	});
}

// export function LoadAndSet(id, time){
//     playertn.loadVideo(id).then(function(id) {
//         playertn.setCurrentTime(time);
//         playertn.play().then(function(){
//             playertn.pause();
//         })
//         // Play();
//     });
// }



export function Play(){
	player.play();

}   

export function Pause(){
	player.pause();
	document.dispatchEvent(pausedEvent);
} 

document.addEventListener('state_togglePause', TogglePlayback);

function TogglePlayback(){
	player.getPaused().then(function(paused){
		if(paused){
			Play();
		}
		else{
			Pause();
			setTimeout(function(){State.ChangeState('togglePause');}, 100);
		}   
	});

}


export function SetPlaybackPosition(timestamp){
	player.setCurrentTime(timestamp);
}

// let sound = false;

export function SetSound(state){
	if(state){
		player.setVolume(1);
	}
	else{
		player.setVolume(0);
	}
	// sound = state;
}

// function ToggleSound(){
// 	SetSound(!sound);
// }
