import whatwouldyoudoNL from '../../assets/sounds/watzoujijdoen.wav';
import whatwouldyoudoEN from '../../assets/sounds/whatwouldyoudo.mp3';
import introEN from '../../assets/sounds/intro_english.mp3';
import introNL from '../../assets/sounds/intro.wav';
import outroEN from '../../assets/sounds/outro_english.mp3';
import outroNL from '../../assets/sounds/outro.wav';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const files = {
	'intro': {
		'en': introEN,
		'nl': introNL
	},
	'outro': {
		'en': outroEN,
		'nl': outroNL
	},
	'whatwouldyoudo': {
		'en': whatwouldyoudoEN,
		'nl': whatwouldyoudoNL
	}
};

export const Audio = () => {


	const {t, i18n} = useTranslation();

	const whatwouldyoudoFile = useMemo(() => {
		console.log({i18n, t, language: i18n.language});
		return files.whatwouldyoudo[i18n.language];
	},[t, i18n]);
	
	const outroFile = useMemo(() => {
		return files.outro[i18n.language];
	},[t, i18n]);

	const introFile = useMemo(() => {
		return files.intro[i18n.language];
	},[t, i18n]);


	return (
		<>
			<>
				<audio id="decisions-sound">
					<source src={whatwouldyoudoFile} type="audio/wav"/>
				</audio>

				<audio id="intro-sound">
					<source src={introFile}  type="audio/wav"/>
				</audio>

				<audio id="outro-sound">
					<source src={outroFile}  type="audio/wav"/>
				</audio>
			</>
		</>
	);
};