import styled from 'styled-components';
import Theme from '../../style/theme';

export const Button = styled.button`
    border: none;
    background-color: ${Theme.colors.neutralDarkest};
    color: ${Theme.colors.primary};
    text-transform: uppercase;
    font-family: 'Graphik';
    /* padding-bottom: 0.4rem; */
    padding-inline: 1rem;

    cursor: pointer;

    &:hover{
        text-decoration: underline;
    }

	// Extra small screens
	@media (max-width: ${Theme.responsive.media.sm}){
		font-size: 2rem;
		padding-bottom: 5px;

	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		font-size: 2.2em;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		font-size: 2.2em;
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){   
		font-size: 2.2em;
	}
`;