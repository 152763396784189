import styled from 'styled-components';
import Theme from '../../style/theme';

export const Title = styled.h1`
    
    background-color: ${Theme.colors.neutralDarkest};
    font-family: 'Graphik';
    color: ${Theme.colors.neutralLightest};
    text-transform: uppercase;
    text-align: left;
    padding-inline: 1rem;
	padding-bottom: 0.1rem;

    width: fit-content;

	// Extra small screens
	@media (max-width: ${Theme.responsive.media.sm}){
		font-size: 1.5rem;
		width: 100%;
		text-align: center;
	}

`;