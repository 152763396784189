// Generates unique id

function uuidv4() {
	return 'xxxxxx'.replace(/[xy]/g, function(c) {
		var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

export function Host(callback){
	let roomCode = uuidv4();
	let ws = new WebSocket('wss://fairplay.tqwi.nl/ws/host/' + roomCode + '/');
	let data = {
		'ws' : ws,
		'roomcode' : roomCode,
	};
	ws.onopen = function(){callback(data);};
}

export function Connect(callback, roomCode){
	let ws = new WebSocket('wss://fairplay.tqwi.nl/ws/player/' + roomCode + '/');
	let data = {
		'ws' : ws,
		'roomcode' : roomCode,
	};
	ws.onopen = function(){callback(data);};

	ws.onclose = function () {
		setTimeout(function (){Connect(callback, roomCode);}, 1000);
	};
}